<app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
<div *ngIf="showModal" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full mx-4">
        <div class="text-center">
            <img src="../../../assets//logo_fundacao.png" alt="Logo" class="mx-auto h-40 w-auto">
        </div>
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="mb-6">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="email">Email</label>
                <div class="relative">
                    <i-feather name="mail"
                        class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></i-feather>
                    <input type="text" id="email" formControlName="email" (keyup.enter)="submit()"
                        class="shadow appearance-none border rounded w-full py-3 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Informe o seu e-mail" required />
                </div>
                <div
                    *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)">
                    <p class="text-red-500 text-xs italic mt-1">Por favor informe o seu e-mail!</p>
                </div>
            </div>
            <div class="mb-8">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="token">Token</label>
                <div class="relative">
                    <i-feather name="key"
                        class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></i-feather>
                    <input type="text" id="token" formControlName="token" (keyup.enter)="submit()"
                        class="shadow appearance-none border rounded w-full py-3 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Informe o seu token" required />
                </div>
                <div
                    *ngIf="form.controls['token'].invalid && (form.controls['token'].dirty || form.controls['token'].touched)">
                    <p class="text-red-500 text-xs italic mt-1">Por favor informe o seu token!</p>
                </div>
            </div>

            <p *ngIf="isEmptyLogin" class="text-red-500 text-xxs text-center strong mt-1 mb-1">Digite seus dados de
                acesso</p>
            <p *ngIf="isInvalidLogin" class="text-red-500 text-xxs text-center strong mt-1 mb-1">Usuário e/ou senha
                inválidos</p>
            <div class="flex items-center justify-center">
                <button type="submit"
                    class="custom-sidebar-color  text-white font-bold py-3 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center">
                    <i-feather name="send" class="mr-2"></i-feather>
                    Enviar
                </button>
            </div>
        </form>
    </div>
</div>