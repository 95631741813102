<style>
    .icon-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    }

    .search-icon {
        position: absolute;
        left: 10px;
        pointer-events: none;
        color: gray;
    }

    .search-input {
        padding-left: 2.5rem;
    }

    @media screen and (max-width: 768px) {
        #send-message-icon {
            margin-left: 1.5rem !important;
        }
    }

    @media screen and (max-width: 640px) {
        .icon-wrapper {
            flex-direction: column;
            align-items: flex-start;
        }

        .search-input {
            width: 100%;
            padding-left: 2rem;
        }
    }

    @media screen and (max-width: 768px) {
        .fixed-bottom {
            position: fixed;
            bottom: 0;
            width: 100%;
            z-index: 10;
        }
    }

    #main-container {
        height: calc(100vh - 60px);
        overflow-y: auto;
    }
</style>

<app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>

<div class="overflow-hidden w-full h-screen relative flex flex-col">
    <div class="flex flex-col flex-1 max-w-full h-screen">
        <div
            class="custom-content-color sticky top-0 z-10 flex items-center border-b border-white/20 p-1 text-gray-200 sm:pl-3 md:hidden">
            <button type="button"
                class="custom-text-color -ml-0.5 -mt-0.5 h-10 w-10 flex items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                (click)="toggleSidebar()">
                <span class="sr-only">Open sidebar</span>
                <i-feather name="menu"></i-feather>
            </button>
            <div class="w-full flex mb-2 mt-1 items-center justify-center">
                <input type="search" id="default-search" name="searchInput" [(ngModel)]="searchText"
                    (input)="searchMessages()"
                    class="custom-input-color custom-text-color block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Pesquisar..." required />
            </div>
            <button type="button" class="px-3" (click)="clearChat()">
                <i-feather class="custom-text-color" name="plus"></i-feather>
            </button>
        </div>

        <div id="topBar"
            class="custom-content-color sticky top-0 z-10 hidden md:flex items-center border-b border-white/20 p-1 text-gray-200 sm:pl-3">
            <button type="button"
                class="-ml-0.5 -mt-0.5 h-10 w-10 flex items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:hover:text-white"
                (click)="toggleSidebar()">
                <span class="sr-only">Open sidebar</span>
                <i-feather class="custom-text-color" name="menu"></i-feather>
            </button>
            <form class="mt-2 mb-3 max-w-xl mx-auto flex-1">
                <label for="default-search"
                    class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" id="default-search" name="searchInput" [(ngModel)]="searchText"
                        (input)="searchMessages()"
                        class="custom-input-color custom-text-color block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Pesquisar..." required />
                </div>
            </form>
            <div
                class="flex items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:hover:text-white mr-6">
                <i-feather style="height: 34px; width: 34px;"
                    class="custom-text-color rounded-full border border-gray-600" name="user"></i-feather>
            </div>
        </div>

        <div id="main-container"
            class="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
            <div class="flex-1 overflow-hidden">
                <div class="custom-content-color react-scroll-to-bottom--css-ikyem-79elbk h-full">
                    <div #scrollContainer class="react-scroll-to-bottom--css-ikyem-1n7m0yu">
                        <div *ngIf="!showEmptyChat && conversation.length > 0"
                            class="flex flex-col items-center text-sm">
                            <app-message *ngFor="let message of highlightedMessages; let index = index"
                                [message]="message" style="width: 100%;"></app-message>
                            <div class="w-full h-40 md:h-10 flex-shrink-0"></div>
                        </div>
                        <div *ngIf="showEmptyChat" class="relative w-full flex flex-col h-full">
                            <h1
                                class="text-2xl sm:text-4xl font-semibold text-center text-gray-200 dark:text-gray-600 flex gap-2 items-center justify-center h-screen">
                                Askin.AI
                            </h1>
                        </div>
                        <div class="flex flex-col items-center text-sm dark:bg-gray-800"></div>
                    </div>
                </div>
            </div>
            <div #bottomOfChatRef
                class="fixed-bottom custom-content-color left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent pt-2">
                <form
                    class="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl"
                    (submit)="sendMessage()">
                    <div class="relative flex flex-col h-full flex-1 items-stretch md:flex-col">
                        <div *ngIf="errorMessage" class="mb-2 md:mb-0">
                            <div class="h-full flex ml-1 md:w-full md:m-auto md:mb-2 gap-0 md:gap-2 justify-center">
                                <span class="text-red-500 text-sm">{{ errorMessage }}</span>
                            </div>
                        </div>
                        <div
                            class="custom-input-color flex flex-col w-full py-2 flex-grow md:py-3 md:pl-4 relative border border-black/10 bg-white dark:border-gray-900/50 dark:text-white rounded-md shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]">
                            <textarea #textAreaRef [(ngModel)]="message" tabIndex="0" name="chatTextArea"
                                style="height: 24px; max-height: 200px; overflow-y: hidden;"
                                placeholder="Digite uma mensagem..."
                                class="custom-text-color custom-input-color m-0 w-full resize-none border-0 bg-transparent p-0 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent pl-2 md:pl-0"
                                (keydown)="handleKeypress($event)"></textarea>
                            <button [disabled]="isLoading || message.length === 0"
                                class="custom-text-color absolute p-1 rounded-md bottom-1.5 md:bottom-1 bg-transparent right-1 md:right-2 disabled:opacity-40"
                                style="rotate: 45deg;">
                                <i-feather id="send-message-icon"
                                    class="custom-text-color h-1 w-1 mr-1 xs:ml-6 sm:ml-6 md:ml-2"
                                    name="send"></i-feather>
                            </button>
                        </div>
                    </div>
                </form>
                <div
                    class="px-3 pt-2 pb-3 text-center text-xs text-black/50 dark:text-white/50 md:px-4 md:pt-3 md:pb-6">
                    <span class="custom-text-color">
                        Askin.AI | Powered by Lumini IT Solutions | 2024
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>