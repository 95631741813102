<app-modal-text-display *ngIf="showModalResume" (close)="closeModalResume()" [title]="title" [resume]="resume"></app-modal-text-display>
<div [ngClass]="{
    'group': true,
    'w-full': true,
    'text-gray-800': !isUser,
    'dark:text-gray-100': true,
    'border-black/10': true,
    'custom-chat-border-color': true,
    'custom-user-chat-color': isUser,
    'custom-ia-chat-color': !isUser,
    'bg-gray-50': !isUser
  }">
  <div class="text-base gap-4 md:gap-6 md:max-w-2xl lg:max-w-xl xl:max-w-3xl flex lg:px-0 m-auto w-full">
    <div class="flex flex-row gap-4 md:gap-6 md:max-w-2xl lg:max-w-xl xl:max-w-3xl p-4 md:py-6 lg:px-0 m-auto w-full">
      <div class="w-8 flex flex-col relative items-end">
        <div
          class="relative h-7 w-7 p-1 rounded-sm text-white flex items-center justify-center text-opacity-100r">
          <i-feather *ngIf="isUser; else elseBlock" class="h-3 w-3 custom-text-color-dark hi-user" name="user"></i-feather>
          <ng-template #elseBlock>
            <i-feather class="h-4 w-4 custom-text-color-dark " name="aperture"></i-feather>
          </ng-template>
        </div>
        <div
          class="text-xs flex items-center justify-center gap-1 absolute left-0 top-2 -ml-4 -translate-x-full group-hover:visible !invisible">
          <button disabled class="text-gray-300 dark:text-gray-400"></button>
          <span class="flex-grow flex-shrink-0">1 / 1</span>
          <button disabled class="text-gray-300 dark:text-gray-400"></button>
        </div>
      </div>
      <div class="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
        <div class="flex flex-grow flex-col gap-3">
          <div class="min-h-5 flex flex-col items-start gap-4 whitespace-pre-wrap break-words">
            <div class="custom-text-color-dark markdown prose w-full break-words dark:prose-invert dark">
              <p [innerHTML]="getSafeContent()"></p>

              <div *ngIf="message.files && message.files.length > 0" class="mt-2">
                <div *ngFor="let file of message.files" class="flex items-center gap-2">
                  <div class="mt-4">
                  <span class="font-bold cursor-pointer" (click)="openFileInNewTab(file.url)">
                    <i-feather style="height: 15px !important; width: 15px !important;" class="custom-text-color-dark" name="download"></i-feather> {{ file.file_name }}
                  </span>
                  <span class="text-gray-500 cursor-pointer"
                        (mouseover)="fileResumeHovered = true"
                        (mouseleave)="fileResumeHovered = false"
                        (click)="openModalResume(file)">
                        <span class="custom-text-color-dark hover:underline"> - {{ file.resume | slice:0:40 }}</span>
                    <span *ngIf="file.resume.length > 40">...</span>
                  </span>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>