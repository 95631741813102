<main class="overflow-hidden w-full h-screen relative flex">
    <app-modal-login></app-modal-login>
    <div *ngIf="isSidebarVisible" class="md:hidden" style="z-index: 99;">
        <app-sidebar-mobile (toggleSidebarVisibility)="toggleSidebarVisibility($event)" (newChatEvent)="clearChat()"></app-sidebar-mobile>
    </div>

    <div *ngIf="isSidebarVisible" class="custom-sidebar-color dark hidden flex-shrink-0 md:flex md:w-[260px] md:flex-col">
        <div class="flex h-full min-h-0 flex-col">
            <app-sidebar style="height: 100%;" (newChatEvent)="clearChat()"></app-sidebar>
        </div>
    </div>

    <div style="width: 100% !important;">
        <app-chat (toggleSidebarVisibility)="toggleSidebarVisibility($event)"></app-chat>
    </div>    
</main>