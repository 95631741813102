<div class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" (click)="closeModal()">
    <div class="custom-content-color rounded-lg shadow-lg p-6 max-w-4xl w-full max-h-[80vh] overflow-y-auto relative" (click)="$event.stopPropagation()">
      <button (click)="closeModal()" class="absolute top-2 left-2 text-gray-500 hover:text-gray-800">
        <i-feather name="x" class="h-6 w-6"></i-feather>
      </button>
      <div class="text-center">
        <h2 class="custom-text-color-dark text-xl font-bold mb-4">{{title}}</h2>
        <p class="custom-text-color-dark text-left">
            {{resume}}
        </p>
      </div>
    </div>
  </div>
  