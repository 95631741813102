import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatService } from 'src/app/services/chat-service';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
})
export class ModalLoginComponent {
  showModal: boolean = false;
  isLoading = false;
  isInvalidLogin = false;
  isEmptyLogin = false;

  form: FormGroup;

  constructor(private fb: FormBuilder, private chatService: ChatService) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      token: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.showModal = true;
  }

  submit() {
    this.isInvalidLogin = false;
    this.isEmptyLogin = false;

    if (!this.form.valid) {
      this.isEmptyLogin = true;
      return;
    }

    this.isLoading = true;

    this.chatService
      .validatedLogin(
        this.form.get('email')?.value || '',
        this.form.get('token')?.value || ''
      )
      .then((validated) => {
        if (validated && validated.status) {
          this.showModal = false;
        } else {
          this.isInvalidLogin = true;
        }
        this.isLoading = false;
      });
  }
}
