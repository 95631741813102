import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

// icons
import { FeatherModule } from 'angular-feather';
import { Aperture, Download, Key, LogOut, Mail, Menu, MessageCircle, Paperclip, Plus, Send, Sunset, Terminal, User, X } from 'angular-feather/icons';

// components
import { AppComponent } from './app.component';
import { ChatComponent } from './components/chat/chat.component';
import { MessageComponent } from './components/message/message.component';
import { SidebarMobileComponent } from './components/sidebar-mobile/sidebar-mobile.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { IndexComponent } from './pages/index/index.component';

// services
import { ThemeService } from './services/theme-service';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { ModalTextDisplayComponent } from './components/modal-text-display/modal-text-display.component';

const icons = {
  Menu,
  Plus,
  User,
  Terminal,
  Aperture,
  Send,
  Sunset,
  LogOut,
  MessageCircle,
  Mail,
  Key,
  Download,
  X
};

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    SidebarComponent,
    SidebarMobileComponent,
    ChatComponent,
    ModalLoginComponent,
    MessageComponent,
    LoadingOverlayComponent,
    ModalTextDisplayComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FeatherModule.pick(icons)
  ],
  providers: [
    ThemeService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
