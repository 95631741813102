import { Injectable } from '@angular/core';
import { TalkApiService } from '../shared/http/talk-api.service';
import { ResponseAccess, ResponseModel } from '../shared/models/response-types';
import { BuddyTalkApi, BuddyTalkRequest } from '../shared/models/talk-api';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private readonly buddyApi: TalkApiService) {}

  async validatedLogin(email: string, token: string): Promise<ResponseAccess> {
    const request: BuddyTalkRequest = {
      buddyApi: BuddyTalkApi.ThothApi,
      resource: `acesso?email=${email}&token=${token}`,
    };

    try {
      const response = await this.buddyApi.get<ResponseAccess>(request);
      return response;
    } catch (exception: unknown) {
      if (
        typeof exception === 'object' &&
        exception !== null &&
        'error' in exception
      ) {
        throw this.buddyApi.getErrorMessage(
          (exception as { error: any }).error
        );
      } else {
        throw new Error('An unexpected error occurred');
      }
    }
  }

  async getAwnser(prompt: string): Promise<ResponseModel> {
    const request: BuddyTalkRequest = {
      buddyApi: BuddyTalkApi.ThothApi,
      resource: `perguntar?pergunta=${prompt}`,
    };

    try {
      const response = await this.buddyApi.get<ResponseModel>(request);
      return response;
    } catch (exception: unknown) {
      if (
        typeof exception === 'object' &&
        exception !== null &&
        'error' in exception
      ) {
        throw this.buddyApi.getErrorMessage(
          (exception as { error: any }).error
        );
      } else {
        throw new Error('An unexpected error occurred');
      }
    }
  }
}
