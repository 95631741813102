import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-modal-text-display',
  templateUrl: './modal-text-display.component.html',
})
export class ModalTextDisplayComponent {
  @Input() title: any;
  @Input() resume: any;
  @Output() close = new EventEmitter<void>();

  closeModal() {
    this.close.emit();
  }
}
