import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat-service';
import { ResponseModel } from 'src/app/shared/models/response-types';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
})
export class ChatComponent {
  isLoading = false;
  isSidebarVisible = false;
  showEmptyChat = true;

  searchText: string = '';
  conversation: { content: string; role: string; files: any }[] = [];
  highlightedMessages: { content: string; role: string; files: any }[] = [];

  selectedModel = { name: 'Default Model' };
  errorMessage = '';
  message = '';

  @ViewChild('scrollContainer') scrollContainer: ElementRef | undefined;

  @Output() toggleSidebarVisibility: EventEmitter<boolean> = new EventEmitter();

  constructor(private chatService: ChatService) {}

  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
    this.toggleSidebarVisibility.emit(this.isSidebarVisible);
  }

  searchMessages() {
    this.updateHighlightedMessages();
  }

  updateHighlightedMessages() {
    if (this.searchText.trim() === '') {
      this.highlightedMessages = this.conversation.map((message) => ({
        ...message,
        content: message.content,
      }));
      return;
    }
    const regex = new RegExp(this.searchText, 'gi');
    this.highlightedMessages = this.conversation.map((message) => ({
      ...message,
      content: message.content.replace(
        regex,
        (match) => `<mark>${match}</mark>`
      ),
    }));
  }

  setMessage(value: string) {
    this.message = value;
  }

  handleKeypress(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  async sendMessage() {
    var newMessage = this.message.trim();

    if (newMessage.length < 1) {
      return;
    }

    this.isLoading = true;

    var response: ResponseModel = {
      resposta: '',
    };

    response = await this.chatService.getAwnser(newMessage).finally(() => {
      this.isLoading = false;
    });

    this.showEmptyChat = false;
    this.message = '';

    this.conversation.push({
      content: newMessage,
      role: 'user',
      files: null
    });

    this.conversation.push({
      files: response.fontes,
      content: response.resposta,
      role: 'admin',
    });

    setTimeout(() => {
      this.scrollToBottom();
      this.updateHighlightedMessages();
    }, 100);
  }

  clearChat() {
    this.conversation = [];
    this.highlightedMessages = [];
    this.showEmptyChat = true;
  }

  private scrollToBottom(): void {
    try {
      setTimeout(() => {
        if (this.scrollContainer?.nativeElement) {
          this.scrollContainer.nativeElement.scrollTop =
            this.scrollContainer.nativeElement.scrollHeight;
        }
      });
    } catch (err) {
      console.error('Erro ao rolar para o fundo:', err);
    }
  }
}
