<div class="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20">
  <nav class="flex h-full flex-1 flex-col space-y-1 p-2">
    <div class="sticky top-0 z-10 mb-10 flex h-20 items-center justify-center"
      style="margin-top: 2rem; margin-right: 1.2rem;">
      <div class="bg-white rounded-full h-32 w-32 flex items-center justify-center">
        <img src="../../../assets/logo_fundacao.png" height="155" width="155" alt="logo" />
      </div>
    </div>

    <a (click)="newChat()"
      class="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm mb-1 flex-shrink-0 border border-white/20">
      <i-feather name="plus"></i-feather>
      Novo chat
    </a>
    <div class="flex-col flex-1 overflow-y-auto border-b border-white/20">
      <div class="flex flex-col gap-2 pb-2 text-gray-100 text-sm">
        <a
          class="flex py-3 px-3 items-center gap-3 relative rounded-md  cursor-pointer break-all hover:pr-4 group">
          <i-feather name="message-circle" class="h-4 w-4"></i-feather>
          <div class="flex-1 text-ellipsis max-h-5 overflow-hidden break-all relative">
            Nova conversa
            <div class="absolute inset-y-0 right-0 w-8 z-10 from-gray-900 group-hover:from-[#2A2B32]">
            </div>
          </div>
        </a>
      </div>
    </div>
    <a (click)="toggleDarkMode()"
      class="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm">
      <i-feather name="sunset"> class="h-4 w-4"</i-feather>
      Modo escuro
    </a>
    <!-- <a
      class="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm">
      <i-feather name="log-out" class="h-2 w-2"></i-feather>
      Sair
    </a> -->
  </nav>
</div>