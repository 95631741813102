import { Component, EventEmitter, Output } from '@angular/core';
import { ThemeService } from 'src/app/services/theme-service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  @Output() newChatEvent = new EventEmitter<void>();
  
  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    this.toggleDarkMode();
  }

  toggleDarkMode() {
    this.themeService.toggleDarkMode();
  }

  newChat(): void {
    this.newChatEvent.emit();
  }
}
