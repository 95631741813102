import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html'
})
export class MessageComponent {
  @Input() message: any;
  isUser: boolean = false;
  fileResumeHovered: boolean = false;
  showModalResume: boolean = false;

  title: any;
  resume: any;

  ngOnInit(): void {
    this.isUser = this.message.role === 'user';
  }

  getSafeContent(): string {
    return this.message.content;
  }

  openFileInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  openModalResume(file: any){
    this.showModalResume = true;
    this.title = file.file_name;
    this.resume = file.resume;
  }

  closeModalResume(){
    this.showModalResume = false;
  }
}
