import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private originalColor: string;
  private isDarkMode: boolean = true;

  constructor() {
    this.originalColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--primary-bg-color')
      .trim();
  }

  toggleDarkMode(): void {
    this.isDarkMode = !this.isDarkMode;
    if (!this.isDarkMode) {
      this.setSidebarBackgroundColor('#305E85');
      this.setContentBackgroundColor('#FAFAFA');
      this.setInputBackgroundColor('white');
      this.setTextColor('#4B5563');
      this.seTextColorDark('#0D0D10');
      this.setUserChatBackgroundColor('#FAFAFA');
      this.setNotUserChatBackgroundColor('white');
      this.setChatBorderColor('white');
    } else {
      this.setSidebarBackgroundColor('#202123');
      this.setContentBackgroundColor('#343541');
      this.setInputBackgroundColor('#40414E');
      this.setTextColor('#9A9AA0');
      this.seTextColorDark('#F3F4F6');
      this.setUserChatBackgroundColor('#343541');
      this.setNotUserChatBackgroundColor('#444654');
      this.setChatBorderColor('#40414E');
    }
  }

  setSidebarBackgroundColor(color: string): void {
    document.documentElement.style.setProperty('--sidebar-bg-color', color);
  }

  setContentBackgroundColor(color: string): void {
    document.documentElement.style.setProperty('--content-bg-color', color);
  }

  setTextColor(color: string): void {
    document.documentElement.style.setProperty('--text-color', color);
  }

  seTextColorDark(color: string): void{
    document.documentElement.style.setProperty('--text-color-dark', color);
  }

  setInputBackgroundColor(color: string): void {
    document.documentElement.style.setProperty('--input-bg-color', color);
  }

  setUserChatBackgroundColor(color: string) {
    document.documentElement.style.setProperty('--user-chat-bg-color', color);
  }

  setNotUserChatBackgroundColor(color: string) {
    document.documentElement.style.setProperty('--ia-chat-bg-color', color);
  }

  setChatBorderColor(color: string){
    document.documentElement.style.setProperty('--border-color-chat', color);
  }
}
