export enum BuddyTalkApi {
  ThothApi,
}

export interface BuddyTalkRequest {
  buddyApi: BuddyTalkApi;
  resource: string;
  body?: any;
}

export interface BuddyTalkResponse<TData> {
  success: boolean;
  data: TData;
  errors: Array<string>;
}
