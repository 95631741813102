import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs';
import { ThemeService } from 'src/app/services/theme-service';
import { ChatComponent } from 'src/app/components/chat/chat.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
})
export class IndexComponent {
  title = 'buddy-talk-ai';
  isSidebarVisible = true;

  @ViewChild(ChatComponent) appChatComponent!: ChatComponent;
  
  constructor(
    private breakpointObserver: BreakpointObserver,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.themeService.toggleDarkMode();
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(map((result) => result.matches))
      .subscribe((matches) => {
        this.isSidebarVisible = !matches;
      });
  }

  toggleSidebarVisibility(state: boolean) {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
 
  clearChat(): void {
    this.appChatComponent.clearChat();
  }
}
