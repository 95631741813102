import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar-mobile',
  templateUrl: './sidebar-mobile.component.html',
})
export class SidebarMobileComponent {
  isSidebarVisible = false;

  @Output() newChatEvent = new EventEmitter<void>();
  @Output() toggleSidebarVisibility: EventEmitter<boolean> = new EventEmitter();

  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
    this.toggleSidebarVisibility.emit(this.isSidebarVisible);
  }

  clearChat(): void {
    this.isSidebarVisible = false;
    this.toggleSidebarVisibility.emit(this.isSidebarVisible);
    this.newChatEvent.emit();
  }
}
