<div id="headlessui-portal-root">
  <div data-headlessui-portal>
    <button type="button" aria-hidden="true"
      class="fixed top-1 left-1 w-1 h-0 p-0 m-1 overflow-hidden whitespace-nowrap border-0"></button>
    <div>
      <div class="fixed inset-0 bg-gray-600 bg-opacity-75 opacity-100"></div>
      <div class="fixed inset-0 flex">
        <div class="relative w-full max-w-xs flex-1 flex-col custom-sidebar-color translate-x-0"
          id="headlessui-dialog-panel-:rf:" data-headlessui-state="open">
          <div class="absolute top-0 right-0 -mr-12 pt-2 opacity-100">
            <button type="button"
              class="ml-1 flex h-10 w-10 items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              (click)="toggleSidebar()">
              <span class="sr-only">Close sidebar</span>
              <i class="text-white text-2xl" aria-hidden="true">×</i>
            </button>
          </div>
          <app-sidebar (newChatEvent)="clearChat()"></app-sidebar>
        </div>
        <div class="w-14 flex-shrink-0"></div>
      </div>
    </div>
    <button type="button" aria-hidden="true"
      class="fixed top-1 left-1 w-1 h-0 p-0 m-1 overflow-hidden whitespace-nowrap border-0"></button>
  </div>
</div>